import {
  ApolloProvider,
  useQuery as useQueryApollo,
  useMutation as useMutationApollo
} from '@apollo/client/react';
import { OperationVariables, gql } from '@apollo/client/core';
import { GraphQLService } from '../services/GraphQLService/GraphQLService';
import {
  ClientUseMutationResult,
  ClientUseQueryResult
} from '../services/GraphQLService/types';
import { ReactNodeType, ReactType } from './types';
import GraphQLReactTools from './GraphQLReactTools';

const WebGraphQLReactTools: GraphQLReactTools = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useQuery<TData = Record<string, any>>(
    query: string,
    options?: { variables: OperationVariables }
  ): ClientUseQueryResult<TData> {
    const result = useQueryApollo(gql(query), options);
    return result;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useMutation<TData = Record<string, any>>(
    mutation: string,
    options?: { variables: OperationVariables }
  ): ClientUseMutationResult<TData> {
    const result = useMutationApollo(gql(mutation), options);
    return result as ClientUseMutationResult<TData>;
  },
  createGraphQLProvider(react: ReactType) {
    const GraphQLProvider = ({
      children,
      client
    }: {
      children: ReactNodeType;
      client: GraphQLService;
    }) => {
      return react.createElement(
        ApolloProvider,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { client: (client as any)._apolloClient, children },
        children
      );
    };
    return GraphQLProvider;
  }
};

export default WebGraphQLReactTools;
