/* eslint-disable @typescript-eslint/no-explicit-any */
import { OperationVariables } from '@apollo/client/core';
import GraphQLReactTools from './GraphQLReactTools';
import {
  ClientUseMutationResult,
  ClientUseQueryResult
} from '../services/GraphQLService/types';
import { GraphQLService } from '../services/GraphQLService/GraphQLService';
import { ReactType } from './types';
import { v4 as uuidv4 } from 'uuid';

let _React: ReactType;

const NativeGraphQLReactTools: GraphQLReactTools = {
  useQuery<TData = Record<string, any>>(
    query: string,
    options?: { variables: OperationVariables }
  ): ClientUseQueryResult<TData> {
    const [data, setData] = _React.useState();
    const [refetch, setRefetch] = _React.useState(0);
    const [queryID, setQueryID] = _React.useState('');

    const sendMessage = (event: any) => {
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify(event));
    };

    const receiveMessage = (event: any) => {
      try {
        if (event?.data.type === 'refetchQueries') {
          setRefetch(refetch + 1);
        }

        if (
          event?.data.type === 'graphqlResult' &&
          event?.data.payload.ID === queryID
        ) {
          setData(event.data.payload.response.data);
        }
      } catch (e) {
        console.error('Error in useQuery parsing message data:', e);
      }
    };

    // Listener for graphql result
    _React.useEffect(() => {
      window.addEventListener('message', receiveMessage);
      return () => window.removeEventListener('message', receiveMessage);
    }, [queryID]);

    // Send event to native to fetch queries
    // Generate a unique ID for this query instance
    _React.useEffect(() => {
      const ID = uuidv4();
      setQueryID(ID);
      sendMessage({
        type: 'query',
        payload: { ID, query, variables: options?.variables }
      });
    }, [query, refetch]);

    return data;
  },
  useMutation<TData = Record<string, any>>(
    mutation: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options?: { variables: OperationVariables }
  ): ClientUseMutationResult<TData> {
    const [data, setData] = _React.useState(null);
    const [loading, setLoading] = _React.useState(false);
    const [error, setError] = _React.useState(null);
    const [mutationID, setMutationID] = _React.useState('');

    _React.useEffect(() => {
      const ID = uuidv4();
      setMutationID(ID);
    }, [mutation]);

    const mutate = _React.useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async (variables = {}) => {
        setLoading(true);
        setError(null);

        try {
          sendMessage({
            type: 'mutation',
            payload: {
              ID: mutationID,
              query: mutation
              // TODO: Variables?
            }
          });
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      },
      [mutation, mutationID]
    );

    const sendMessage = (event: any) => {
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify(event));
    };

    const receiveMessage = _React.useCallback(
      (event) => {
        try {
          if (
            event?.data.type === 'graphqlResult' &&
            event?.data.payload?.ID === mutationID
          ) {
            setData(event.data.payload.response.data);
          }
        } catch (e) {
          console.error('Error in useMutation parsing message data:', e);
        }
      },
      [mutationID]
    );

    // Listener for graphql result
    _React.useEffect(() => {
      window.addEventListener('message', receiveMessage);
      return () => window.removeEventListener('message', receiveMessage);
    }, [receiveMessage]);

    return [mutate, { data, loading, error }];
  },
  createGraphQLProvider(React: ReactType) {
    _React = React;
    const GraphQLProvider = ({
      children
    }: {
      children: any;
      client: GraphQLService;
    }) => {
      return React.createElement('div', {}, children);
    };
    return GraphQLProvider;
  }
};

export default NativeGraphQLReactTools;
